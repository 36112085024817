import * as React from 'react';

import Layout from '../components/layout';

const IndexPage = () => (
  <>
    <Layout>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
      <div className="home-page">Home Page</div>
    </Layout>
    <style jsx>
      {`
        .home-page {
          color: #004e61;
        }
      `}
    </style>
  </>
);

export default IndexPage;
